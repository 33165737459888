svg {
 position: absolute;
 /* top: 50%;
 left: 50%; */
 /* transform: translate(-50%, -50%); */
 /* background-color: #000; */
 background-color: transparent;
}
.path {
 animation: draw 20s infinite;
 animation-timing-function: linear;
}
.path01 {
 animation-delay: 0s;
}
.path02 {
 animation-delay: 1s;
}
.path03 {
 animation-delay: 2s;
}
.path04 {
 animation-delay: 3s;
}
.path05 {
 animation-delay: 4s;
}


@keyframes draw {
 0% {
 }
 100% {
   stroke-dashoffset: 0;
   stroke-opacity: 1;
 }
}