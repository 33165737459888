/* This styles are aplied to the entire page, in order to have a general margin */
.generalStyles {
	margin: 0 24px;
}
/*The general margin is gonna be 0 on big screens*/
@media only screen and (min-width: 740px) {
	.generalStyles {
		margin: 0;
	}
}

/*----------Main Page----------*/

.banner {
	position: absolute;
	top: 24%;
	left: 50%;
	transform: translate(-50%, 0);
	display: flex;
	flex-direction: column;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin: 8px 0; */
	height: inherit;
	padding: 0 100px;
}
.form {
	width: 100%;
}
.formCTA {
	display: flex;
	justify-content: space-evenly;
	margin: 20px 0;
}
.teamContainer {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	gap: 20px;
	padding: 120px 0;
}
.formContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 120px 0;
}

.containerReverse {
	display: flex;
	justify-content: center;
	align-items: center;
	height: inherit;
	padding: 0 100px;
	background-color: transparent;
	/* background-color: #50c6d8;  */
	/*rgba(0, 127, 197, 1)*/
}
/*--------------- cards ------------- */
.svgContainer {
	position: relative;
	height: 20rem;
	width: 50%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 1.5s;
}

.textContainer {
	width: 50%;
	padding: 10px;
	position: relative; /*absolute to work*/
}

.heading {
	text-align: center;
	font-family: 'dosis';
	font-size: 60px;
	text-transform: capitalize;
	color: none;
	-webkit-text-stroke: 1px black;
	color: #2e3d49;
	letter-spacing: 0.05em;
	text-shadow: 4px 4px 0px #d5d5d5, 7px 7px 0px rgba(255, 255, 255, 0.2);
}

/* Small screens */
.info {
	font-family: 'roboto', sans-serif;
	color: #0c191a;
	text-align: center;
	line-height: 25px;
	font-size: 1rem;
}
/* medium screens */
@media (min-width: 750px) {
	.info {
		font-size: 1.3rem;
	}
}

/* big screens */
@media (min-width: 1450px) {
	.info {
		font-size: 1.5rem;
	}
}

/* footer */
footer {
	/* background: #dddee1; */
	padding: 2vh 0;
	text-align: center;
	bottom: 0;
	width: 100%;
}

/*------- Neon --------*/
.neon {
	color: #000;
	font-size: 2em;
	text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ff,
		0 0 80px #0ff, 0 0 90px #0ff, 0 0 100px #0ff, 0 0 150px #0ff;
}

/* box description */

.container_description {
	position: inherit;
	margin: auto;
	width: 100%;
}

.box {
	/* color: rgb(130, 179, 18); */
	/* background: blue; */
	position: relative;
	text-align: center;
	width: 100%;
	height: 400px;
	/* margin: 10px; */
}

.box .text_description {
	position: absolute;
	top: 1px;
	left: 1px;
	right: 1px;
	bottom: 1px;
	border: 2px solid #515350;
	padding: 30px;
}

.box .text_description h2 {
	font-size: 40px;
	margin: 0 0 10px;
}

.box .text_description p {
	font-size: 18px;
}

.box .container_description {
	/* display: flex; */
	/* flex-wrap: wrap; */
	justify-content: center;
}

/* Page style */

.background {
	/* background: linear-gradient(#0a0a0a, #02588a); */
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* height: 200%; */
	overflow: hidden;
}

.scrollText {
	margin: 0;
	padding: 0;
	text-align: center;
	font-size: 2.5rem;
	font-weight: bold;
}

.banner img {
	max-width: 100%;
	height: auto;
}

.cardLeft {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	height: 20rem;
	width: 70%;
	text-align: center;
	border-radius: 10px;
	margin-left: 30% !important;
}

.parallax {
	margin-right: 15%;
}

.sticky {
	margin-left: 1%;
	background-color: black;
	/* background-color: rgba(236, 240, 8, 0.5); */
	/* color: white; */
}

.sticky img {
	max-width: 100%;
	height: auto;
}

.blue {
	background-color: #5b65ad;
	color: white;
}

.purple {
	background-color: #9d65d0;
}

.cyberpunk {
	width: 600px;
	border: 30px solid #2e3d49;
	border-right: 5px solid #2e3d49;
	border-left: 5px solid #2e3d49;
	border-bottom: 20px solid #2e3d49;
	padding: 0 30px;
	clip-path: polygon(
		0px 25px,
		26px 0px,
		calc(60% - 25px) 0px,
		60% 25px,
		100% 25px,
		100% calc(100% - 10px),
		calc(100% - 15px) calc(100% - 10px),
		calc(80% - 10px) calc(100% - 10px),
		calc(80% - 15px) 100%,
		80px calc(100% - 0px),
		65px calc(100% - 15px),
		0% calc(100% - 15px)
	);
	background-color: none;
	position: relative;
}

/* Buttons */
.logoPi {
	cursor: pointer;
}

.buttons {
	position: absolute;
	font-weight: bolder;
	/* top: 1%; */
	left: 0;
	width: 100vw;
	max-width: 100%;
	text-align: center;
	z-index: 1;
	background-color: rgba(255, 255, 255, 0.493);
	backdrop-filter: blur(2px);
}
.button {
	flex: 1;
	justify-content: end;
	gap: 8px;
}

/* coloreo de selected icons */
.navItem {
	cursor: pointer;
	text-decoration: none;
	color: #698296bc;
}
.navItem:hover {
	color: rgba(6, 23, 108, 0.882);
}

.active {
	color: rgb(6, 23, 108);
	font-weight: bolder;
	text-decoration: underline;
	text-decoration-color: rgb(6, 23, 108);
}

.canvas {
	background-color: rgb(184, 8, 8);
	overflow: hidden;
}

/* card services*/
.cardServices {
	background-color: transparent;
	height: 100%;
	align-content: center;
	text-justify: newspaper;
	font-size: 1rem;
}

.cardText {
	padding: 18px;
	text-align: justify;
}

.cardData {
	padding-top: 2%;
	align-content: center;
}

.cardDevelop {
	padding-top: 2%;
	align-content: center;
}

.cardAI {
	padding-top: 2%;
	align-content: center;
}

.image {
	width: 20%;
}

#OurTeam {
	height: 664px;
}

@media (max-width: 750px) {
	.scrollText {
		font-size: 1.8rem;
	}
	#OurTeam {
		height: 1000px;
	}
	.parallax {
		margin-right: 1.5rem;
	}

	.sticky {
		margin-left: 1.5rem;
	}
	.image {
		width: 10%;
	}
}

@media only screen and (max-width: 745px) {
	.container {
		flex-direction: column;
		padding: 60px 0;
	}
	.containerReverse {
		flex-direction: column-reverse;
		padding: 60px 0;
	}
	.teamContainer {
		padding: 60px 0;
	}
	.formContainer {
		padding: 60px 0;
	}
	.svgContainer {
		width: 100%;
	}
	.textContainer {
		width: 100%;
	}
	.cyberpunk {
		width: 400px;
	}
	svg {
		width: 300px;
	}
}

/* MOBILE PART OF THE NAVIGATION TRIGGERED */
@media only screen and (max-width: 990px) {
	.button {
		width: 45%;
		margin: 0 auto;
	}
}

/*----------------- Footer ------------------*/
/*----------------- Social Media ------------------*/
.socialLinks {
	/* display: flex; */
	align-items: center;
}

.socialIcon {
	color: #050000;
	transition: color 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
}

.socialIcon:hover {
	color: #0077b5; 
}
  
