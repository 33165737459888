.our-team {
	padding: 50px 0;
	margin-bottom: 10px;
	min-width: 300px;
	background-color: transparent;
	text-align: center;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}

.our-team .picture {
	display: inline-block;
	height: 130px;
	width: 130px;
	margin-bottom: 10px;
	z-index: 1;
	position: relative;
}

.our-team:hover .picture::before {
	height: 100%;
}

.our-team .picture::after {
	content: '';
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #1369ce;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
.our-team .picture img {
	width: 100%;
	height: auto;
	border-radius: 50%;
	transform: scale(1);
	transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
	box-shadow: 0 0 0 14px #fff;
	transform: scale(0.7);
}

.our-team .title {
	display: block;
	font-size: 15px;
	color: #4e5052;
	text-transform: capitalize;
}

.socialModified {
	position: absolute;
	bottom: 0px;
	border-radius: 2px;
	left: 0;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 5px;
	width: 100%;
	height: 40px;
	transition: all 0.5s ease 0s;
}

.socialModified svg {
	color: #1369ce;
	position: relative;
	transition: all 0.3s ease 0s;
}

.socialModified svg:hover {
	color: #5c98dd;
}
@media only screen and (max-width: 1000px) {
	.socialModified {
		width: 50%;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
}
