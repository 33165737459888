#paths {
	animation: drawcbba 15s infinite linear;
	stroke-dasharray: 100, 560, 50, 560, 32, 400;
	stroke-dashoffset: 6400;
}

@keyframes drawcbba {
	100% {
		stroke-dashoffset: 0;
		stroke-opacity: 1;
	}
}

/* CIRCUIT LINES */
.st0 {
	fill: none;
	stroke: rgb(141, 189, 189);
	stroke-width: 8;
	stroke-miterlimit: 10;
}
.st0-path {
	fill: none;
	stroke: rgba(7, 7, 7, 0.5);
	stroke-width: 5;
	stroke-miterlimit: 10;
}

/* PUNTOS */
.st1 {
	fill: none;
	stroke: #1d1d1b;
	stroke-width: 5.0001;
	stroke-miterlimit: 10.0002;
}

/* puntos */
.st2 {
	fill: none;
	stroke: rgb(141, 189, 189);
	stroke-width: 5.0002;
	stroke-miterlimit: 10.0004;
}
.st3 {
	fill: none;
	stroke: rgb(141, 189, 189);
	stroke-width: 5.0142;
	stroke-miterlimit: 10.0285;
}
.st4 {
	fill: none;
	stroke: rgb(141, 189, 189);
	stroke-width: 7;
	stroke-miterlimit: 10;
}

/* base circuits */
.st5 {
	fill: none;
	stroke: #1d1d1b;
	stroke-width: 14;
	stroke-miterlimit: 10;
	animation: cbba-base 5s infinite linear;
}

/* @keyframes cbba-base {
	100% {
		stroke: rgb(0, 255, 0);
	}
} */

/* fondo logo */
.st6 {
	fill: #010101;
}
/* borde fondo logo */
.st7 {
	fill: url(#SVGID_1_);
}
/* punto del logo */
.st8 {
	fill: #009fe3;
}
/* sombra logo */
.st9 {
	fill: #575756;
}
.st10 {
	fill: #283e67;
}
/* relleno del logo */
.st11 {
	fill: #ffffff;
}
