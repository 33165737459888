#logo * {
	fill: transparent;
	stroke: rgba(20, 119, 180, 0.705);
	stroke-dasharray: 2600;
	stroke-dashoffset: 2600;
	animation: logo-anim ease-in forwards;
}

/* lines */
#cloudLines {
	animation-duration: 50s;
	animation-delay: 3s;
}

/* dots */
#cloudDots {
	animation-duration: 15s;
	animation-delay: 1s;
}

#logo {
	animation: rotation 25s infinite linear 0s;
}

@keyframes logo-anim {
	100% {
		fill: rgb(0, 24, 39);
		stroke-dashoffset: 0;
		stroke: rgb(28, 118, 173);
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
